html {
  -webkit-font-smoothing: antialiased;
}

p {
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(rgba(40, 40, 50, 0.2), rgba(40, 40, 50, 0.2)), url('./assets/images/ruka.jpeg') no-repeat;
  background-size: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-button {
  background-color: rgba(255, 255, 255, 0.95);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0.02857em;
  padding: 10px 20px;
  color: #434652;
  border: 0;
  box-shadow: none;
  border-radius: 0.7rem;
  font-size: calc(2px + 1vmin);
  filter: drop-shadow(0 0 0.4rem #434652);
}

.App-header-bar {
  padding: 2rem 0;
  font-size: calc(10px + 2vmin);
  width: 100%;
  position: fixed;
  min-height: 4vh;
  max-height: 10vh;
  top: 2rem;
  vertical-align: top;
  display: flex;
  justify-content: center;
  z-index: 1000;
  pointer-events: auto;
}

.App-header-home {
  position: absolute;
  left: 15rem;
  width: 10%;
}

.App-header-items {
  position: absolute;
  top: 2.3rem;
  width: 20%;
  right: 15rem;
  display: flex;
  justify-content: space-between;
}

.kartta {
  border: 0;
  border-radius: 1rem;
}

a {
  color: white;
  filter: drop-shadow(.05rem .1rem 0.1rem #434652);
  text-decoration: none!important;
  cursor: pointer;
  font-size: calc(4px + 2vmin);
}

a.contact_details {
  color: #1a1a1a;
  filter: none;
}

td {
  padding: 0 1rem;
}

.App-title {
  min-height: 60vh;
  width: 100%;
  position: relative;
  font-size: calc(20px + 4vmin);
  align-items: center;
  display: flex;
  justify-content: center;
}

.App-title-text {
  position: absolute;
  width: 100%;
  top: 10rem;
  filter: drop-shadow(0 0 0.4rem #434652);
}

.App-title-button {
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 25rem;
  width: 12rem;
  padding: 10px 20px;
  color: #434652;
  border: 0;
  box-shadow: none;
  border-radius: 0.7rem;
  font-size: calc(3px + 1.5vmin);
  filter: drop-shadow(0 0 0.4rem #434652);
}

.App-box {
  background-color: white;
  padding: 30px 30px;
  width: 80%;
  position: relative;
  border-radius: 20px;
  min-height: 50vh;
  color: #434652;
}

.App-box-child {
  width: 47%;
  left: 30px;
  height: 85%;
  position: absolute;
}

.App-box-child-child {
  background-color: rgba(252,254,255,0.31);
  width: 95%;
  position: relative;
  margin: 10% 5%;
  text-align: left;
  font-size: calc(4px + 1.5vmin);
  color: #434652;
}

.App-box-child-2 {
  width: 47%;
  right: 30px;
  height: 85%;
  position: absolute;
}

p.text-dark {
  color: #434652;
}

.App-link {
  color: #c5e6f1;
}

.App-form-wrapper {
  width: 90%;
  background-color: rgb(239, 146, 92);
  border-radius: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 65vh;
}

h1.App-form-h1 {
  position: absolute;
  font-size: calc(5px + 2vmin);
  top: 2rem;
  min-height: 5vh;
  filter: drop-shadow(0 0 0.4rem #434652);
}

form {
  top: 5rem;
  position: absolute;
}

.form_group {
  position: relative;
  margin: 45px 0;
}

textarea {
  resize: none;
}

input,
textarea {
  background: none;
  color: rgba(249, 249, 249, 0.95);
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 500px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(249, 249, 249, 0.95);
}

textarea:focus {
  outline: none;
}
input:focus {
  outline: none;
}

textarea:focus ~ label {
  top: -14px;
  font-size: 12px;
  color: #434652;
}
input:focus ~ label {
  top: -14px;
  font-size: 12px;
  color: #434652;
}
textarea:valid ~ label {
  top: -14px;
  font-size: 12px;
  color: #434652;
}
input:valid ~ label {
  top: -14px;
  font-size: 12px;
  color: #434652;
}
textarea:focus ~ .form_bar:before {
  width: 515px;
}
input:focus ~ .form_bar:before {
  width: 515px;
}

label {
  color: rgba(249, 249, 249, 0.95);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}

.form_bar {
  position: relative;
  display: block;
  width: 500px;
  filter: drop-shadow(0 0 0.4rem #434652);
}
.form_bar:before {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #434652;
  transition: 300ms ease all;
  left: 0;
}

.form_button {
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  background-color: #434652;
  position: relative;
  padding: 10px 30px;
  color: rgba(249, 249, 249, 0.95);
  border: 0;
  box-shadow: none;
  border-radius: 0.7rem;
  font-size: calc(3px + 1.5vmin);
  filter: drop-shadow(0 0 0.4rem #434652);
}

footer {
  width: 70%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 4rem 2rem;
}

.App-footer-box {
  position: relative;
  width: 30%;
}

.login-box {
  background-color: #1a1a1a;
}

h2.footer {
  color: #434652;
  font-size: calc(5px + 2vmin);
}

p.footer {
  color: #434652;
  font-size: calc(3px + 1.5vmin);
}

a.footer {
  color: #434652;
  font-size: calc(3px + 1.5vmin);
}

.saukko {
  width: 50%;
  height: 50%;
  position: relative;
  top: 1rem;
}

.front-page-image {
  width: 60%;
  top: 1rem;
  position: relative;
  border-radius: 1rem;
  filter: drop-shadow(0 0 0.4rem #434652);
  cursor: pointer;
}

h2 {
  color: #1a1a1a;
  font-size: calc(5px + 2vmin);
}

.highlighted-link {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
